#_3zcBesA3fx84rmK6iGFnOr {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

._1yJaMxIFGeh3Pyr9V6Dd6- {
  display: flex;
  flex-direction: column;
  margin: 200px auto 0;
  background-color: #fefefe;
  border: 1px solid #888;
  width: 600px;
  padding: 1.5em;
}
._1yJaMxIFGeh3Pyr9V6Dd6- h2 {
  font-size: 1.25em;
  font-weight: 300;
  color: black;
  line-height: 1.5em;
  letter-spacing: -0.52px;
}

.Qnb44r7MczhSIeAoi4LV6 {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin-top: 1em;
}

._1GaPzy0eoZq2BVlFLtumYR {
  display: block;
}

._2t1G8BMzR3-935m0tC_G1N {
  display: none;
}