._2LDF4fyYgcjgzQ-4yGVeJa {
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
  width: 20em;
  text-align: center;
  border-radius: 12px;
  padding: 0.4em 2.8em;
  margin-right: 1em;
  font-size: 14px;
}
._2LDF4fyYgcjgzQ-4yGVeJa._3HNcZSn-9AKAm1F1INkS7n {
  background: #d3d3d3;
}
._2LDF4fyYgcjgzQ-4yGVeJa._3lWhef-0aPfSeSl3wvaL_E {
  background: #60b1e0;
}
._2LDF4fyYgcjgzQ-4yGVeJa._2G4jOEY0CJFfx1Va9dUczk {
  background: #60b1e0;
}
._2LDF4fyYgcjgzQ-4yGVeJa._1IaTw-ZjNGjtXsq1CwgPgf {
  background: #ff9800;
}
._2LDF4fyYgcjgzQ-4yGVeJa._34c-eKPn3TemhfHntqE5z4 {
  background: #afe060;
}
._2LDF4fyYgcjgzQ-4yGVeJa._3wkyDAjTLN_kns_y82sXdf {
  background: #4caf50;
}
._2LDF4fyYgcjgzQ-4yGVeJa._15KU7sHp-qulHyXb7EpoWg {
  background: #f44336;
}
._2LDF4fyYgcjgzQ-4yGVeJa._6MKEWdWFW29q_Psw7ITm4 {
  background: #d3d3d3;
}