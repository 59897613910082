._23ji4hhR4DEPLfaerwM0dc > section > table {
  width: 100%;
  border: 0;
  border-collapse: separate;
  border-spacing: 0 5px;
  font-size: 0.9em;
}
._23ji4hhR4DEPLfaerwM0dc > section > table thead tr th {
  border-bottom: 0.5px solid #bfbfbf;
  border-collapse: separate;
  border-spacing: 5px 5px;
  padding: 1em;
  text-transform: uppercase;
  text-align: left;
  color: #8b8b8b;
}
._23ji4hhR4DEPLfaerwM0dc > section > table td {
  padding: 1em;
  text-align: left;
  text-decoration: none;
  color: #8b8b8b;
  cursor: pointer;
}
._23ji4hhR4DEPLfaerwM0dc > section > table ._167Ui65bdl9Me1-aU19Hfv {
  font-weight: bold;
}