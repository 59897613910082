._2OWjqYt-Jw8-70YaPC7jXH {
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
}
._2OWjqYt-Jw8-70YaPC7jXH > section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #4a4a4a;
  height: 100%;
}
._2OWjqYt-Jw8-70YaPC7jXH > section > aside {
  background: #2b2b2b;
  color: #d5d5d5;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30%;
  padding: 3em 2em;
  text-align: center;
}
._2OWjqYt-Jw8-70YaPC7jXH > section > aside p {
  text-align: left;
}
._2OWjqYt-Jw8-70YaPC7jXH > section > article {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  padding: 3em 5em;
}