._3bOQzkkyOOlWq1yLyZdpXP:hover {
  background-color: #efefef;
  border-radius: 50%;
}
._3bOQzkkyOOlWq1yLyZdpXP:active {
  fill: white;
  background-color: #367397;
  border-radius: 50%;
}

._2UWPLUn6zNEKoKf3YY-zR2:hover {
  background-color: #efefef;
  border-radius: 50%;
}
._2UWPLUn6zNEKoKf3YY-zR2:active {
  fill: white;
  background-color: #367397;
  border-radius: 50%;
}