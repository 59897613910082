._3HljR8LUPaL9c-LvvCp3z5 {
  display: flex;
  flex-direction: column;
}
._3HljR8LUPaL9c-LvvCp3z5 input {
  font-weight: bold;
  font-size: 0.9em;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 0.8em;
  background: #efefef;
}
._3HljR8LUPaL9c-LvvCp3z5 input._1dGQzTF7ZwAqzzzPzrXje1 {
  border: 1px solid #c23934;
}
._3HljR8LUPaL9c-LvvCp3z5 select {
  font-weight: bold;
  font-size: 1em;
  border: 1px solid #efefef;
  border-radius: 4px;
  height: 2.6em;
  background: #efefef;
}
._3HljR8LUPaL9c-LvvCp3z5 select > option {
  font-weight: bold;
}
._3HljR8LUPaL9c-LvvCp3z5 select._1dGQzTF7ZwAqzzzPzrXje1 {
  border: 1px solid #c23934;
}
._3HljR8LUPaL9c-LvvCp3z5 label {
  font-size: 0.9em;
  color: #8b8b8b;
  padding: 0.5em;
}
._3HljR8LUPaL9c-LvvCp3z5 ._3FAzvbzEbfV0wQjZbBoagI {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
._3HljR8LUPaL9c-LvvCp3z5 ._3FAzvbzEbfV0wQjZbBoagI ._3o-hODdUK8lw5rgHsmHw8H, ._3HljR8LUPaL9c-LvvCp3z5 ._3FAzvbzEbfV0wQjZbBoagI ._3J3Sr-FCvm0IAk91lHWtUO {
  border-radius: 4px;
  padding: 0.6em;
  background: silver;
}
._3HljR8LUPaL9c-LvvCp3z5 ._3FAzvbzEbfV0wQjZbBoagI ._3J3Sr-FCvm0IAk91lHWtUO {
  background: #367397;
  color: #ffffff;
}