._3nkjpjzSQsXn_qSPgKVXCT > section {
  display: flex;
  flex-direction: column;
}
._3nkjpjzSQsXn_qSPgKVXCT > section > a {
  color: #367397;
  text-decoration: none;
  cursor: pointer;
}
._3nkjpjzSQsXn_qSPgKVXCT > section > form fieldset {
  margin-bottom: 2em;
}
._3nkjpjzSQsXn_qSPgKVXCT > section > form fieldset > div {
  display: flex;
  flex-direction: column;
}
._3nkjpjzSQsXn_qSPgKVXCT > section > form fieldset > div > * + * {
  margin-top: 1em;
}
._3nkjpjzSQsXn_qSPgKVXCT > section > form fieldset > div:not(:first-child) {
  margin-top: 2em;
}
._3nkjpjzSQsXn_qSPgKVXCT .UL6QU8RRCjGui75y04E-Y {
  float: left;
}
._3nkjpjzSQsXn_qSPgKVXCT ._1lr4BJNg0K1fhz2sjJilbM {
  float: right;
}