@font-face {
  font-family: "MazdaType-Bold";
  src: url(../d2629e081c3dab208b5d09ed0b0bdfab.woff2) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "MazdaType-Medium";
  src: url(../03c0b0c61f6919d8ee4a1a1c6c0d81f0.woff2) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MazdaType-Regular";
  src: url(../f24c208effae153dde37697eb726ceed.woff2) format("truetype");
  font-weight: 500;
  font-style: normal;
}
 * {
  box-sizing: border-box;
}
 body, p, h1, h2, h3, h4, h5, h6, dl {
  margin: 0;
  padding: 0;
  font-family: "MazdaType-Regular", "Roboto", sans-serif;
}
 p {
  font-size: 16px;
  font-style: normal;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 10px;
}
 fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
 main > article {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
 main > article > * {
  flex-basis: 100%;
}
 h4 {
  font-size: 0.9em;
  color: #8b8b8b;
  font-weight: 400;
}
 ._1pHXDpSY5MhKdTz6svAQnk {
  text-transform: uppercase;
  font-size: 0.7rem;
  color: #8b8b8b;
  font-weight: bold;
}
 ._2ka-tAB7jIEIgGF2blr2Dz {
  font-style: italic;
  color: #8b8b8b;
  font-size: 0.8em;
}
 ._1GLqMeJbM5dMdUfelA06dc {
  color: #c23934;
  font-size: 0.9em;
  padding: 0.5em;
}
 .hRl3s6JStz-5GuZGPVwF6 {
  white-space: pre-wrap;
}
 .hRl3s6JStz-5GuZGPVwF6 h1 {
  text-transform: uppercase;
  font-size: 0.7em;
  color: #8b8b8b;
  font-weight: bold;
}
 .hRl3s6JStz-5GuZGPVwF6 p {
  font-size: 0.9em;
}
 .hRl3s6JStz-5GuZGPVwF6 ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
 .hRl3s6JStz-5GuZGPVwF6 ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
 .hRl3s6JStz-5GuZGPVwF6 blockquote {
  margin: 0;
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
 ._3ltJXDksXoq2ksm0BB0yoY {
  margin-left: auto;
}
 ._3ltJXDksXoq2ksm0BB0yoY ._1NFjz8s5YgMJtlxpmFTVlz {
  color: #4a4a4a;
}