._2MAGkmHE_Kl-X7judcCOcG {
  border: none;
  border-radius: 4px;
  font-size: 1.2em;
  padding: 0.8em 1em;
  color: #ffffff;
  background: #367397;
  font-size: 0.9em;
  width: 100%;
}
._2MAGkmHE_Kl-X7judcCOcG:hover {
  color: #000000;
  background: #4fb7f3;
}
._2MAGkmHE_Kl-X7judcCOcG:focus {
  color: #ffffff;
  background: #114c6e;
}
._2MAGkmHE_Kl-X7judcCOcG:disabled {
  color: #ffffff;
  background: #ccdce5;
}

._31ydna0GyhpAPEuZKH6dYQ {
  border: none;
  border-radius: 4px;
  font-size: 1.2em;
  padding: 0.8em 1em;
  color: #ffffff;
  background: #367397;
  font-size: 0.8em;
}
._31ydna0GyhpAPEuZKH6dYQ:hover {
  color: #000000;
  background: #4fb7f3;
}
._31ydna0GyhpAPEuZKH6dYQ:focus {
  color: #ffffff;
  background: #114c6e;
}
._31ydna0GyhpAPEuZKH6dYQ:disabled {
  color: #ffffff;
  background: #ccdce5;
}

._1yokpFdxz52l2zTaoJGazX {
  cursor: pointer;
  background: none;
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 0 0.8em;
  color: #367397;
  border: 1px solid transparent;
  border-radius: 4px;
}
._1yokpFdxz52l2zTaoJGazX:hover {
  border: 1px solid #367397;
}
._1yokpFdxz52l2zTaoJGazX:focus {
  color: #114c6e;
}

._2m34cUaoQWYI4QWqoJZgIq {
  background: none;
  border: none;
}
._2m34cUaoQWYI4QWqoJZgIq:focus {
  outline: 0;
}