.tRV5JcHwpOaeFbfDxsABA {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tRV5JcHwpOaeFbfDxsABA > section {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}
.tRV5JcHwpOaeFbfDxsABA a {
  margin-top: 2em;
  color: #367397;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

._1jrMbpJyNVaX-GWYihRW0T {
  background: #2b2b2b;
  color: #d5d5d5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
._1jrMbpJyNVaX-GWYihRW0T .hSEWznfoTPmSoRrk1ypBM {
  text-transform: uppercase;
}
._1jrMbpJyNVaX-GWYihRW0T p {
  margin: 1.2em 15%;
  width: 100%;
  max-width: 800px;
  padding: 0 25px;
}

.k2Vhd8KOaVMg0IKmVPId8 {
  background: linear-gradient(180deg, #FFF 0%, #F5F5F5 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._1sRvLuUEJAUTWPPUtAOKOX {
  margin-top: -15em;
}
._1sRvLuUEJAUTWPPUtAOKOX h3 {
  text-align: center;
  margin: 1em;
}

@media (max-width: 565px) {
  ._1sRvLuUEJAUTWPPUtAOKOX {
    margin-top: initial;
  }
}
._2926sSwEuAOqocKZl32_w5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  padding: 0 25px;
}
._2926sSwEuAOqocKZl32_w5 input {
  font-size: 1.2em;
  padding: 0.4em 0.8em;
}

.SonlNR95wRgeVGCzyKW2S {
  width: 100%;
}