._2M1qADqMYGB1Y0xbsHQL5n {
  width: 25em;
}

._2PZ1ln3P-EYRuVl8EElV2S {
  list-style-type: none;
}

._1H54VFyHwLi0hhnlfTQfar {
  font-weight: bold;
  text-decoration: underline;
}

._37uAHFKcTQHezOAeDOqA23 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
._37uAHFKcTQHezOAeDOqA23 > div {
  display: flex;
  flex-direction: column;
}
._37uAHFKcTQHezOAeDOqA23 > div > input, ._37uAHFKcTQHezOAeDOqA23 > div > select {
  margin-top: 1em;
  margin-right: 1em;
}
._37uAHFKcTQHezOAeDOqA23 > div > span {
  margin-top: 1em;
}