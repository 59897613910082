._2H8VRcgHL3_411JBmh2hB5 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}
._2H8VRcgHL3_411JBmh2hB5 > section {
  display: flex;
}
._2H8VRcgHL3_411JBmh2hB5 > section > div {
  display: flex;
  flex-direction: row;
  width: 1.5em;
  height: 1.5em;
  background-color: #367397;
  border-radius: 100%;
  animation: _3f1xSHvTOlHrJzonYbkisx 0.7s 0s infinite linear;
}
._2H8VRcgHL3_411JBmh2hB5 > section > div:first-child,
._2H8VRcgHL3_411JBmh2hB5 > section :last-child {
  animation: _3f1xSHvTOlHrJzonYbkisx 0.7s 0.35s infinite linear;
}

@keyframes _3f1xSHvTOlHrJzonYbkisx {
  50% {
    transform: scale(0.75);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}