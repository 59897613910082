._3IS2bfWVWkO6tXYslutnCA {
  height: 0.5em;
  margin-bottom: 5em;
  position: relative;
  font-weight: bold;
}
._3IS2bfWVWkO6tXYslutnCA ._2N6T1XptVflGBsp8WvSox {
  height: 100%;
  border-radius: 10px;
  margin-bottom: 1em;
}

.SXbAAwKYs2FIwv5q2XZ9d {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 1em;
  margin-bottom: 2em;
  font-size: 0.8em;
}
.SXbAAwKYs2FIwv5q2XZ9d > div {
  flex-basis: 25%;
}
.SXbAAwKYs2FIwv5q2XZ9d > div:last-child {
  text-align: right;
}
.SXbAAwKYs2FIwv5q2XZ9d ._3sEi1dnvd--G8suh-4BI0_ {
  color: #367397;
}
.SXbAAwKYs2FIwv5q2XZ9d ._2MT1ANyvI9zPHkz0o_B2gK {
  color: #04844b;
}