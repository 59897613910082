._2rk67SU9-mBy2PTbL4kTJt {
  display: inline;
  align-items: center;
  width: 100%;
  height: 9em;
  padding-left: 25px;
  padding-top: 20px;
  background: #2b2b2b;
}

._2r3YioXNLB89sZrastfi28 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2em;
}
._2r3YioXNLB89sZrastfi28 > p {
  color: #D5D5D5;
  font-size: 0.75rem;
  font-weight: 300;
  padding-right: 10px;
}

._1ioMq40Gfuy0NG4QdD6SAS {
  align-items: center;
  height: 0em;
  float: left;
  padding-top: 3px;
}
._1ioMq40Gfuy0NG4QdD6SAS img {
  object-fit: contain;
  height: 4em;
  margin: 0 2em;
}
._1ioMq40Gfuy0NG4QdD6SAS > p {
  color: #D5D5D5;
  font-size: 0.75rem;
  font-weight: 300;
  padding-right: 10px;
}
._1ioMq40Gfuy0NG4QdD6SAS > a {
  color: #D5D5D5;
  font-size: 0.75rem;
  font-weight: 200;
  padding-right: 0;
  text-decoration: none;
  letter-spacing: 0.01071em;
}
._1ioMq40Gfuy0NG4QdD6SAS > div {
  width: 196px;
}
._1ioMq40Gfuy0NG4QdD6SAS > div:last-child {
  width: 196px;
}
._1ioMq40Gfuy0NG4QdD6SAS > div:first-child {
  width: 119px;
}

._C2EnIPEERn9IBh9jU7vS {
  background-color: #ccc;
  height: 11px;
  float: left;
  right: 0;
  width: 1px;
  margin: 5px 9px;
}

._28Arka_mYWm3IFSMHiV2T {
  align-items: center;
  height: 0em;
  float: left;
  color: #D5D5D5;
  font-size: 0.75rem;
  font-weight: 200;
  padding-right: 0;
  text-decoration: none;
  letter-spacing: 0.01071em;
  width: 97px;
}
._28Arka_mYWm3IFSMHiV2T > a {
  color: #D5D5D5;
  font-size: 0.75rem;
  font-weight: 200;
  padding-right: 0;
  text-decoration: none;
  letter-spacing: 0.01071em;
}
._28Arka_mYWm3IFSMHiV2T img {
  margin: 0;
  vertical-align: middle;
  padding-right: 5px;
  width: 25px;
}

@media screen and (max-width: 1030px) {
  ._2r3YioXNLB89sZrastfi28 {
    height: 4em;
  }
}
@media screen and (max-width: 568px) {
  ._2r3YioXNLB89sZrastfi28 {
    height: 5em;
  }
}
@media screen and (max-width: 467px) {
  ._1ioMq40Gfuy0NG4QdD6SAS {
    height: 21px;
  }
}
.kauw97V6tPpZs8IGP_fIV {
  width: 320px;
  padding-left: 35px;
}

._196xoOsGzq3x74fd6Mrns2 {
  width: 150px;
}

.EjSfaTGyP7re3uAb79_-L {
  width: 100px;
}

._3adLziiyPdcJYg2ox7YXQZ {
  width: 220px;
}

p {
  color: #D5D5D5;
  font-size: 0.75rem;
  font-weight: 300;
  margin-bottom: unset;
  padding-right: 10px;
}