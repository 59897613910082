._2wmVr3x78OUPh0nNnvBJ8m > div {
  display: flex;
  flex-direction: row;
}
._2wmVr3x78OUPh0nNnvBJ8m ._1k-ENuKWiqrt-qv_TJZ4sC {
  margin-top: unset;
}
._2wmVr3x78OUPh0nNnvBJ8m ._25sNrandSn5Z0E5rUcibaG, ._2wmVr3x78OUPh0nNnvBJ8m ._2broXppnlDzKtLQ8FeXj8g {
  border: 1px solid #efefef;
  border-radius: 4px;
  height: 1.5em;
  width: 1.5em;
  min-width: 1.5em;
  margin-top: 0.5em;
  margin-right: 0.5em;
  background: #efefef;
}
._2wmVr3x78OUPh0nNnvBJ8m ._2broXppnlDzKtLQ8FeXj8g {
  background: #367397;
  color: #ffffff;
}