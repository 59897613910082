._1cDax11fydJOPQm750DiUm {
  background: #2b2b2b;
  min-height: 100%;
  min-width: 100%;
}
._1cDax11fydJOPQm750DiUm h1, ._1cDax11fydJOPQm750DiUm p {
  text-align: center;
}
._1cDax11fydJOPQm750DiUm table {
  margin: 0 auto;
}
._1cDax11fydJOPQm750DiUm .t_N3dXboXPToIV-5ipe6R {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15%;
  filter: invert(1);
}
._1cDax11fydJOPQm750DiUm .x72mbty5t1EfAlN8CRTLI {
  padding-top: 1%;
  color: #d5d5d5;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
._1cDax11fydJOPQm750DiUm ._1D34XGD00YESE7qfB6WXe_ {
  width: 100%;
  max-width: 75px;
}