.lJJDd2CR1zmN8041vlYST {
  display: flex;
  margin-bottom: 2em;
  padding: 1em;
  color: #ffffff;
  border-radius: 4px;
}
.lJJDd2CR1zmN8041vlYST img {
  margin-right: 1em;
}
.lJJDd2CR1zmN8041vlYST h2 {
  font-size: 1em;
}
.lJJDd2CR1zmN8041vlYST .DZKteid1MUv69FI_gnmzo {
  display: flex;
  align-items: center;
  margin-right: 1em;
}
.lJJDd2CR1zmN8041vlYST > div {
  font-size: 0.9em;
}
.lJJDd2CR1zmN8041vlYST > div > div {
  font-size: 0.9em;
}

._19pFjVyIp8SlG9O7gsBjO5 {
  background: #04844b;
}

._3H9OpcQpBLVMmWpoI1n7Ef {
  background: #ffb75d;
  color: black;
}

._3fcXLHatrz3X10v4EyKdsO {
  background: #c23934;
}