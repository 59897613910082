._2y-npiyIghq3QtPbjVoLQY section {
  margin-top: 2em;
}
._2y-npiyIghq3QtPbjVoLQY section > div {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}
._2y-npiyIghq3QtPbjVoLQY section > div > div {
  margin-top: 0.5em;
}
._2y-npiyIghq3QtPbjVoLQY section > div > div > div {
  padding: 0.2em 0;
}
._2y-npiyIghq3QtPbjVoLQY ._16ZSR8N58nOm4EXNRdkBtb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._2y-npiyIghq3QtPbjVoLQY ._3VNnXPKS0ZGEEUw1yjx3Pc {
  display: flex;
  flex-direction: column;
}
._2y-npiyIghq3QtPbjVoLQY ._3VNnXPKS0ZGEEUw1yjx3Pc ._3-Q7nrMgMfAZ7qGngllo7H {
  display: flex;
  flex-direction: row;
  color: #367397;
  font-weight: bold;
  padding: 0.2em 0;
}
._2y-npiyIghq3QtPbjVoLQY ._3VNnXPKS0ZGEEUw1yjx3Pc ._3-Q7nrMgMfAZ7qGngllo7H ._2FGS9fqJuP8pzICBiJiJf5 {
  flex-grow: 1;
}
._2y-npiyIghq3QtPbjVoLQY ._3VNnXPKS0ZGEEUw1yjx3Pc ._3-Q7nrMgMfAZ7qGngllo7H > div {
  padding: 0.2em;
}
._2y-npiyIghq3QtPbjVoLQY ._2x1omCILkc7wOJ8DmAPhVl {
  font-size: 1.5em;
  font-weight: bold;
}
._2y-npiyIghq3QtPbjVoLQY a {
  color: #367397;
}