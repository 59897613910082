._1v2ez4ehOm1pyZv0JY-ocd {
  width: 25em;
}

._1M86m_VhLANV0Lp1Us0YHi {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
._1M86m_VhLANV0Lp1Us0YHi > div {
  display: flex;
  flex-direction: column;
}
._1M86m_VhLANV0Lp1Us0YHi > div > input, ._1M86m_VhLANV0Lp1Us0YHi > div > select {
  margin-top: 1em;
  margin-right: 1em;
}
._1M86m_VhLANV0Lp1Us0YHi > div > span {
  margin-top: 1em;
}

._3WgL8AEruzslU5RptMARK1 {
  padding-bottom: 1em;
}

.g7lBIYi0csUyvQPix2wqw {
  width: 12em;
}