._3sLQqZqM0xd3hGFNcfEIkJ {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._3sLQqZqM0xd3hGFNcfEIkJ > input[type=radio] {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
._3sLQqZqM0xd3hGFNcfEIkJ > input[type=radio] + span::before {
  content: "";
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 1px solid #bfbfbf;
}
._3sLQqZqM0xd3hGFNcfEIkJ > input[type=radio]:checked + span::before {
  box-shadow: inset 0 0 0 0.3em #367397;
}
._3sLQqZqM0xd3hGFNcfEIkJ > label {
  font-size: 0.9em;
  color: #8b8b8b;
  padding: 0 0.5em;
}