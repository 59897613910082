.jrrFF0b5ikKMsvoBPCw7H > fieldset {
  min-width: 25em;
}
.jrrFF0b5ikKMsvoBPCw7H > button {
  width: 25em;
}

._17n9JDZ5WQwTW9apr8ijUO > li + li {
  margin-top: 2em;
  list-style: none;
}
._17n9JDZ5WQwTW9apr8ijUO ul, ._17n9JDZ5WQwTW9apr8ijUO li {
  list-style: none;
}
._17n9JDZ5WQwTW9apr8ijUO ._3hIqi1I9nl1SaoILFokyhp {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  color: #4a4a4a;
  font-size: 1em;
}
._17n9JDZ5WQwTW9apr8ijUO ._3hIqi1I9nl1SaoILFokyhp ._26PFZlEhuQOP9OF1HuYyoP {
  font-weight: bold;
}
._17n9JDZ5WQwTW9apr8ijUO ._3hIqi1I9nl1SaoILFokyhp > div {
  padding-top: 0.2em;
}