._1yFXJFzKF3azSaMsZmarN3 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 5em;
  background: #2a2a2a;
}
._1yFXJFzKF3azSaMsZmarN3 img {
  object-fit: contain;
  height: 4em;
  margin: 0 2em;
}
._1yFXJFzKF3azSaMsZmarN3 > p {
  color: #efefef;
  font-size: 1.125em;
  font-weight: 300;
  margin-bottom: unset;
}