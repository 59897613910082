.x5f-KQgFBeEqWRnD5COOF {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.x5f-KQgFBeEqWRnD5COOF input[type=file] {
  display: none;
}
.x5f-KQgFBeEqWRnD5COOF ._2QWCAwmVW0olmDsi26-vN4 {
  display: block;
  font-size: 0.8em;
  border-radius: 4px;
  padding: 0.6em 1em;
  color: #ffffff;
  background: #367397;
  text-align: center;
  flex-basis: 30%;
  cursor: pointer;
}
.x5f-KQgFBeEqWRnD5COOF ._2hvMLpOBSoXwW9IPW_xOnd {
  border-left: 1px solid #efefef;
  padding-left: 0.5em;
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
}
.x5f-KQgFBeEqWRnD5COOF ._2hvMLpOBSoXwW9IPW_xOnd > div:first-of-type {
  border-top: 1px solid #efefef;
}
.x5f-KQgFBeEqWRnD5COOF ._2hvMLpOBSoXwW9IPW_xOnd ._1Z2ETqz1mNSGish5t-159r {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #367397;
  font-weight: bold;
  padding: 0.5em 0;
  border-bottom: 1px solid #efefef;
}
.x5f-KQgFBeEqWRnD5COOF ._2hvMLpOBSoXwW9IPW_xOnd ._1Z2ETqz1mNSGish5t-159r .ICSZXDxt41H7VJsHKbWyL {
  flex-grow: 1;
}
.x5f-KQgFBeEqWRnD5COOF ._2hvMLpOBSoXwW9IPW_xOnd ._1Z2ETqz1mNSGish5t-159r > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.2em;
}
.x5f-KQgFBeEqWRnD5COOF ._2hvMLpOBSoXwW9IPW_xOnd ._1Z2ETqz1mNSGish5t-159r > div > button {
  background: none;
  border: none;
  cursor: pointer;
}

.uOdoQ48-OpNe70nlmQdmJ {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #367397;
  font-weight: bold;
  padding: 0.5em 0;
  margin-top: 0.5em;
}
.uOdoQ48-OpNe70nlmQdmJ .ICSZXDxt41H7VJsHKbWyL {
  margin-left: 0.5em;
  flex-grow: 1;
}
.uOdoQ48-OpNe70nlmQdmJ > div {
  padding: 0.2em;
}