._1_p3WGI5-r9Yop1e0kpOud {
  display: flex;
  flex-direction: column;
}
._1_p3WGI5-r9Yop1e0kpOud ._3bGwErpEZ7G1Hsxa5d3ptE {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 2em;
}
._1_p3WGI5-r9Yop1e0kpOud ._3bGwErpEZ7G1Hsxa5d3ptE ._96UF01k-AWsuCNpWP_QvC {
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
  font-size: 1em;
}
._1_p3WGI5-r9Yop1e0kpOud ._3bGwErpEZ7G1Hsxa5d3ptE ._96UF01k-AWsuCNpWP_QvC > div {
  padding-top: 0.2em;
}